
import Vue from "vue";
import VueRouter from "vue-router";
import "es6-promise/auto"
import { Route } from "vue-router";
import Home from "@/components/Home.vue";
import PageNotFound from "@/components/PageNotFound.vue";
import Navbar from "@/components/Navbar.vue";
import PageFooter from "@/components/PageFooter.vue";
import VueClipboard from "vue-clipboard2";

Vue.use(VueClipboard);
Vue.use(VueRouter);

import store from "./store/index";

const routes = [
  {
    path: "/",
    component: Home,
    props: (route: Route): unknown => ({
      radarValueProp: route.query.radar,
      dateValueProp: route.query.date,
      intervalValueProp: route.query.interval,
      timeDisplayValueProp: route.query.timedisplay,
      vpChartSelectedSchemeProp: route.query.vpColorScheme,
      vpiChartModeProp: route.query.vpiMode,
      langCodeProp: route.query.lang,
    })
  }, // Example URL: http://localhost:8080/#/?radar=bezav
  { path: "*", component: PageNotFound }
];

const router = new VueRouter({
  mode: "hash",
  routes
});

export default Vue.extend({
  router,
  store,
  name: "App",
  components: {
    Navbar,
    PageFooter
  },
});
